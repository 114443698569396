import * as React from "react"

const TrueArmyworm = () => (
    <div className="py-5">
        <div className="px-4 mx-auto max-w-7xl xl:px-6 lg:px-8">
            <div className="mb-16 xl:mb-18 lg:mb-20" aria-label="NEWA stations map">
                <h1 className="mb-3 font-semibold text-gray-600 xl:mb-5 lg:mb-6 lg:text-2xl">
                True Armyworm</h1>
                <p>Launching 2024.</p>
        </div>
    </div>
    </div>
)

export default TrueArmyworm